@keyframes borderAnimation {
  0% {
    border-color: transparent;
    color: white;
  }

  50% {
    border-color: var(--button-bg-2);
    color: var(--button-bg-2);
  }

  100% {
    border-color: transparent;
    color: white;
  }
}

.status-label-message {
  cursor: pointer;
  position: relative;
  margin-top: 18px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  border-radius: 100px;
  justify-content: center;
  color: var(--button-start-work-text-color);
  z-index: 20;
  background-color: var(--main-bg-color);
  border: 2px solid transparent;
  text-transform: uppercase;
  animation: borderAnimation 1.5s infinite;
}

.under_review{
  cursor: pointer;
  position: relative;
  margin-top: 18px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  border-radius: 100px;
  justify-content: center;
  color: white;
  z-index: 20;
  background-color: var(--main-bg-color);
  border: 2px solid var(--informing-bg-2);
  text-transform: uppercase;
}

.order-pref-container h1{
  padding-bottom: 20px;
}


.confirm_block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 40px;

  .btn {
    margin-bottom: 10px;
    font-family: "Roboto";
    cursor: pointer;
    text-decoration: none;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    border-radius: 12px;
    padding: 15px 0px;
    border: none;
    outline: none;
    color: white;
  }


  .customer_service_btn {
    background-color: var(--button-stop-work-bg);
  }

  .cancel_btn {
    background-color: transparent;
    color: white;
    border: 1px solid transparent;
    border-color: var(--inverted-bg-color);
  }
}


.message-block {
  color: white;
  background-color: var(--main-bg-color);
}

.message-block h2 {
  margin-bottom: 20px;
  font-size: 18px;
}

.message-block ul {
  padding-left: 5px;
  margin-bottom: 20px;
}

.message-block li {
  margin-bottom: 10px;
  font-size: 16px;
  line-height:22px
}

.message-block p {
  font-size: 16px;
  margin-top: 10px;
  line-height:22px
}

.message-block h3 {
  margin-top: 10px;
}

.phone-number-container {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.phone-number {
  font-size: 24px;
  font-weight: bold;
  text-decoration: none;
  color: inherit;
}


.phone-number:visited,
.phone-number:active {
  color: inherit;
}