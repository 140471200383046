.radialGradientCircle {
  display: flex;
  border-radius: 50%;
}


.label {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-family: "Roboto";
  font-weight: 600;
  font-size: 16px;
  font-weight: bold;
  border-radius: 15px;
  background-color: var(--label-bg);

  text-shadow:
    -1px -1px 0 black,
    1px -1px 0 black,
    -1px 1px 0 black,
    1px 1px 0 black;
}