.etherOrderType {
  display: block;

}

.container {
  display: flex;
  flex-direction: column;
}

.priceAndType {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 6px;

.mapButton{
  margin-bottom: 6px;
  background-color: #1DB960;
}

  .price {
    background-color: var(--informing-bg-1);
    color: var(--text-color-1);

    span {
      font-size: 15px;
    }

    path {
      fill: var(--icon-color-2);
    }

    circle {
      stroke: var(--icon-color-2);
    }
  }
  .carType {
    color: var(--text-color-1);

    span {
      font-size: 15px;
    }
  }
  .economyHighlighting {
    background-color: #FEC600;
    margin-right: 0;
  }
  
  .standardHighlighting {
    background-color: #0782F3;
    margin-right: 0;
  }
  
  .comfortHighlighting {
    background-color: #1DB960;
    margin-right: 0;
  }

  .minivanHighlighting {
    background-color: #b03aff;
    margin-right: 0;
  }
  
  .wagonHighlighting {
    background-color: #12b9b9;
    margin-right: 0;
  }

  .defaultBackground {
    background-color: var(--text-color-2);
    margin-right: 0;
  }
  
}

.etherOrderTypeHightDemandIcon {
  display: flex;
  align-items: center;
  gap: 4px;
  margin: 0 10px 0 5px;
}
.distanceInformation{

}


.compensationInfo {
  display: flex;
  align-items: center;
  .priceAndCompensation {
    background-color: #212121;
    position: relative;
    border-radius: 8px;

    path {
      fill: var(--icon-color-3);
    }

    circle {
      stroke: var(--icon-color-3);
    }

    .divider {
      margin: 0 7px;
      width: 2px;
      height: 10px;
      background-color: #fff;
      border-radius: 100px;
    }

    .allPrice,
    .compensation {
      color: var(--text-color-1);
      font-weight: 400;
      font-family: "Roboto";
      font-size: 12px;
    }

    .allPrice {
      margin-top: 1px;
    }

    .compensation {
      display: flex;
      margin-top: 1px;
      .icon {
        display: flex;
        width: 16px;
        height: 16px;
        margin-right: 3px;

        path {
          fill: var(--icon-color-3);
        }
      }

      span {
        margin-top: 2px;
      }
    }
  }
}

.priceForKm {
  margin-left: 5px;
  font-family: "Roboto";
  font-size: 12px;
  letter-spacing: 0.25px;
  width: 55px;
  white-space: nowrap;
}


.destinationPointsContainer{
  display: flex;
  justify-content: space-between;
  margin-left: 5px;
  font-family: "Roboto";
  font-size: 12px;
  letter-spacing: 0.25px;
  white-space: nowrap;
}

.listOfOrders{
  width: 100%;
}

.distanceContainer{
  display: flex;
}

.distance{
  margin-left: 10px;
  display: flex;
}

.highDemandRatio {
  font-family: "Roboto";
  font-weight: 600;
  font-size: 15px;
}
