.button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  width: fit-content;
  min-width: fit-content;
  border-radius: 25%;
  background-color: var(--inverted-bg-color);
  color: var(--main-bg-color);
  border: none;
  cursor: pointer;
  outline: none;

  svg {
    width: 24px;
    height: 24px;
  }
}

.orderHeaderDoubleArrow {
  path {
    stroke: var(--main-bg-color);
  }
}

.redButton{
  path {
    fill: var(--button-bg-2);
  };
}

.greenButton{
  path {
    fill: var(--button-bg-4);
  };
}