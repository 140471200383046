.EtherOrdersDataControl,
.sortingClassCar {
	display: flex;
	align-items: center;
	padding: 0 16px;
}

.sortingClassCar {
	align-items: center;
	justify-content: center;
	display: flex;
    flex-wrap: wrap;
}

.sortingClassCar > * {
    margin-bottom: 5px; 
} 

.leftSide {
	display: flex;
	align-items: center;
	margin-right: auto;
}

.rightSide {
	display: flex;
	align-items: center;
}

.sortIcon {
	cursor: pointer;
	margin: 0 5px;

	path {
		fill: #fff;
	}
}

.sortByEfficiencyAsc,
.sortByDistanceAsc,
.sortByDistanceDesc,
.sortByPriceAsc,
.sortByPriceDesc {
	path {
		stroke: #EE392E;
		fill: #EE392E;
	}
}