.container {
	display: flex;
	align-items: center;
}

.buttonContainer {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
}

.buttonContainer>* {
	margin-bottom: 5px;
}