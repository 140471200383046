.sortingButton {
  display: flex;
  align-items: center;
  margin-right: 5px;

  &.noMargin {
    margin-right: 0;
  }
}

.active {
  background: #ffffff;
  color: #212121;
  transition: background-color 0.3s ease;
}

.content {
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.25px;
}

.icon {
  padding-right: 5px;
}

.filterStyle {
  .content {
    padding: 10px;
  }
}

.big {
  .content {
    padding: 6px 8px;
  }
}

.mediun {
  .content {
    padding: 4px 8px;
  }
}

.small {
  .content {
    padding: 1px 8px;
  }
}

.economyHighlighting {
  background-color: #FEC600;
}

.standardHighlighting {
  background-color: #0782F3;
}

.comfortHighlighting {
  background-color: #1DB960;
}

.minivanHighlighting {
  background-color: #b03aff;
}

.wagonHighlighting {
  background-color: #12b9b9;
  outline: 2px dashed #ffffff; 
  outline-offset: -2px;
}

.defaultBackground {
  background-color: #ffffff;
}