 .EtherOrderMap {
   display: flex;
   justify-content: flex-end;
   overflow: hidden;
   width: 100%;

   .map {
     opacity: 1;
   }

   .showMap {
     opacity: 0;
   }
 }

 .mapModal {
   height: 73vh;
 }

 .loader {
   width: 30px;
 }

 .openMapButton {

   background-color: var(--informing-bg-1);
   color: var(--text-color-1);

   span {
     font-size: 15px;
   }
 }

 .EtherOrderMap {
   width: 100%;
   height: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
 }

 .mapContainer {
   width: 100%;
   height: 100%;
   position: relative;
 }

 .map {
   width: 100%;
   height: 100%;
   border-radius: 8px;
   overflow: hidden;
 }

 .showMap {
   opacity: 1;
   transition: opacity 0.3s ease-in-out;
 }